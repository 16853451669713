import { useNetworkState } from "@uidotdev/usehooks";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { ReconnectingAnimation } from "../shared/Loaders/ReconnectingAnimation";

const InternetStatus = () => {
  const toastId = "connectivity";
  const network = useNetworkState();

  useEffect(() => {
    if (!network.online) {
      toast.dismiss();
      toast.loading("No Internet Connection, please wait...", {
        style: { backgroundColor: "#2E3237", color: "#FFFFFF" },
        icon: <ReconnectingAnimation />,
        id: toastId,
      });
    } else {
      toast.dismiss(toastId);
    }
  }, [network.online]);

  return null;
};

export default InternetStatus;
